<template>
  <div class="">
    <MainTitle align="center">
      <template #before>
        {{ $t('title') }}
      </template>
    </MainTitle>

    <ul class="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-x-8 text-left">
      <li v-for="link in ownerSupportLinks" :key="link.title" class="flex items-center bullet-list">
        <AppLink
          v-if="link.link"
          :to="link.link"
          class="flex-1 bullet text-primary-700 flex items-center gap-x-4 h-30 md:h-auto [ pl-8 md:pl-[30px] lg:pr-[64px] md:py-[38px] ] relative"
        >
          <svg-icon :name="link.icon" width="40" height="40" />
          <span class="text-base">{{ link.title }}</span>
        </AppLink>
        <div
          v-else
          class="flex-1 bullet text-primary-700 flex items-center gap-x-4 h-30 md:h-auto [ pl-8 md:pl-[30px] lg:pr-[64px] md:py-[38px] ] relative"
        >
          <svg-icon :name="link.icon" width="40" height="40" />
          <div class="relative flex-1">
            <span class="text-base">{{ link.title }}</span>
          </div>
        </div>
        <div
          class="z-1 ml-auto lg:-ml-16 [ border-17 md:border-3 border-page md:border-white ] [ mx-auto ] [ bg-coloration-secondary rounded-full ] [ w-20 h-20 ] [ flex items-center justify-center ]"
        >
          <svg-icon
            name="arrow-left"
            width="20"
            height="16"
            class="text-white"
            :class="{
              'transform rotate-180': $i18n.locale == 'en',
            }"
          />
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});

const ownerSupportLinks = [
  {
    title: $t('registerProducts'),
    icon: 'register-product',
    link: '/register-product-intro',
    description: $t('productManualDescription'),
  },
  {
    title: $t('bookAnAppointment'),
    icon: 'check-appointments',
    link: '/book-appointment-intro',
    description: $t('warrantyInfoDescription'),
  },
  {
    title: $t('checkProductsManual'),
    icon: 'brochure',
    link: '/search-manuals',
    description: $t('productManualDescription'),
  },
  {
    title: $t('exploreWarrantyInfo'),
    icon: 'waranty',
    link: '/warranty-info',
    description: $t('warrantyInfoDescription'),
  },
  {
    title: $t('troubleshooting'),
    icon: 'troubleshooting',
    link: '',
    description: $t('troubleshootingDescription'),
  },
  {
    title: $t('contactCustomerService'),
    icon: 'contact-customer-service',
    link: '',
    description: $t('contactCustomerService'),
  },
];
</script>
<i18n>
{
    "en": {
        "title": "Owner Support",
        "registerProducts": "Register Products",
        "bookAnAppointment": "Book an Appointment",
        "checkProductsManual": "Check Products Manual",
        "exploreWarrantyInfo": "Explore Warranty Info",
        "productManualDescription": "Learn more about your product from the digital manual",
        "warrantyInfoDescription": "All you need to know about products’ warranty",
        "shop": "Shop ",
        "category": " Category",
        "troubleshooting": "Troubleshooting",
        "troubleshootingDescription": "Troubleshooting",
        "contactCustomerService": "Contact Customer Service",
        "contactCustomerServiceDescription": "Contact Customer Service"
    },
    "ar": {
        "title": "الدعم الخاص بالمُلاك",
        "registerProducts": "تسجيل المنتجات",
        "bookAnAppointment": "حجز موعد",
        "checkProductsManual": "دليل استخدام المنتج",
        "exploreWarrantyInfo": "معلومات الضمان",
        "productManualDescription": "تعرف على المزيد من المعلومات عن المنتج",
        "warrantyInfoDescription": "كل ما تريد معرفته عن ضمان المنتج",
        "shop": "تسوق ",
        "category": " الفئات",
        "troubleshooting": "التشغيل",
        "troubleshootingDescription": "التشغيل",
        "contactCustomerService": "اتصل بخدمة العملاء",
        "contactCustomerServiceDescription": "اتصل بخدمة العملاء"
    }
}
</i18n>
<style lang="postcss" scoped>
.bullet-list {
  position: relative;
  &::before {
    @apply absolute top-0 left-0 right-4 h-full bg-secondary-300;
    content: '';
    border-radius: 80px;

    @screen lg {
      border-radius: 100px 100px 100px 100px;
    }
  }
}

.bullet-description {
  max-width: calc(100% - 100px);
}

.bg-coloration-secondary {
  background-image: linear-gradient(to top, #1ba4e6, rgba(27, 164, 230, 0.7));
}
</style>
